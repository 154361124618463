.Group-form {
	margin-top: 25px;

	&__name {
		font-size: 13px;
		font-weight: 500;
		letter-spacing: -0.1px;
		color: #000000;
		margin-bottom: 13px;
	}

	&__radio {
		display: flex;
		flex-wrap: wrap;
	}
}

.Form-input {
	margin-top: 25px;

	&__name {
		margin-bottom: 10px;
		font-size: 13px;
		font-weight: 500;
		letter-spacing: -0.1px;
		color: #000000;

		span {
			color: #828282;
		}
	}

	input {
		width: 100%;
		padding: 6px 9px;
		border-radius: 4px;
		line-height: 18px;
		height: 30px;
		border: solid 1px #d3d9de;
		background-color: #ffffff;
		outline: none;
		font-size: 13px;
		box-sizing: border-box;

		&::placeholder {
			font-size: 13px;
			line-height: 1.46;
			letter-spacing: -0.1px;
			color: #828282;
		}
	}

	&__hint {
		margin-top: 10px;
		font-size: 12.5px;
		letter-spacing: -0.1px;
		color: #828282;
	}
}

.Form-checkbox {
	position: relative;
	margin-top: 25px;
	display: flex;
	align-items: center;

	input[type="checkbox"] {
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
		cursor: pointer;
		outline: 0 !important;

		&:hover + label {
			color: rgba(#000, .8);
		}

		& + label {
			cursor: pointer;
			position: relative;
			padding-left: 22px;
			font-size: 13px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.46;
			letter-spacing: -0.1px;
			color: #000000;

			&:before {
				content: '';
				width: 15px;
				height: 15px;
				border-radius: 3px;
				border: solid 1px #c1c9d1;
				background-color: #ffffff;
				display: inline-block;
				position: absolute;
				top: 1px;
				left: 0;
				box-sizing: border-box;
			}

			&:after {
				content: "";
				color: #2c7be5;
				background: url("../img/icons/checked.svg");
				position: absolute;
				top: 1px;
				left: 0;
				font-size: 16px;
				opacity: 0;
				transform: scale(0);
				width: 15px;
				height: 15px;
				box-sizing: border-box;
			}
		}

		&:checked + label:after {
			opacity: 1;
			transform: scale(1);
		}
	}
}

.Form-radio {
	position: relative;
	display: flex;

	& + & {
		margin-left: 19px;
	}

	input[type="radio"] {
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
		cursor: pointer;
		outline: 0 !important;

		&:hover + label {
		}

		&:hover + label:before {
			background-color: #f2f4f8;
		}

		& + label {
			cursor: pointer;
			position: relative;
			padding-left: 23px;
			font-size: 13px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.46;
			letter-spacing: -0.1px;
			color: #000000;

			&:before {
				content: '';
				width: 15px;
				height: 15px;
				border-radius: 50%;
				display: inline-block;
				position: absolute;
				left: 0;
				top: 1px;
				border: solid 1px #c1c9d1;
				background-color: #ffffff;
				box-sizing: border-box;
			}

			&:after {
				content: '';
				background: #5181b8;
				position: absolute;
				top: 5px;
				left: 4px;
				opacity: 0;
				transform: scale(0);
				width: 7px;
				height: 7px;
				border-radius: 50%;
			}
		}

		&:checked + label:after {
			opacity: 1;
			transform: scale(1);
		}

		&:checked + label:before {
			border-color: #5181b8;
		}
	}
}

.Form-radio-color {
	position: relative;
	display: flex;
	align-items: center;

	& + & {
		margin-left: 0;
	}

	input[type="radio"] {
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
		cursor: pointer;
		outline: 0 !important;

		&:hover + label {
			opacity: .85!important;
		}

		& + label {
			position: relative;
			width: 34px;
			height: 34px;
			pointer-events: none;
			cursor: pointer;

			&:before {
				content: '';
				width: 32px;
				height: 32px;
				border-radius: 50%;
				display: inline-block;
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
				border: 2px solid transparent;
				background-color: #fff;
				opacity: 0;
				box-sizing: border-box;
			}

			&:after {
				content: '';
				position: absolute;
				top: 5px;
				left: 5px;
				width: 24px;
				height: 24px;
				border-radius: 50%;
				cursor: pointer;
				pointer-events: all;
			}
		}

		&:checked + label:before {
			opacity: 1;
		}

		&:checked + label:before {
			border-color: #07F;
		}
	}

	&__option {
		position: absolute;
		width: 26px;
		height: 27px;
		background: url('../img/icons/colors.png') no-repeat;
		-webkit-background-size: contain;
		background-size: contain;
		border-radius: 50%;
		z-index: 1;
		left: 7px;
		top: 4px;

		&:hover {
			cursor: pointer;
			background: rgba(#000, .5);
		}
	}

	&.color-black label:after {
		background: #000;
	}

	&.color-blue label:after {
		background: #07F;
	}

	&.color-transparent label:after {
		background: url("../img/icons/transparent.png");
		background-size: contain;
	}

	&.color-gradient label:after {
		background: url("../img/icons/gradient.jpg") center;
		background-size: contain;
	}

	&.color-photo label:after {
		background: url("../img/icons/photo.svg") center no-repeat;
		background-size: 14px 12px;
		border: 1px solid #5181b8;
		box-sizing: border-box;
	}

	&_color-photo {
		position: relative;
		width: 26px;
		height: 26px;
		cursor: pointer;
		border-radius: 50%;
		background: url("../img/icons/photo.svg") center no-repeat;
		background-size: 14px 12px;
		border: 1px solid #5181b8;
		box-sizing: border-box;
		margin-left: 4px;

		&:hover {
			opacity: .85;
		}
	}
}

.Form-radio-icon {
	position: relative;

	& + & {
		margin-left: 10px;
	}

	input[type="radio"] {
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
		cursor: pointer;
		outline: 0 !important;

		&:hover + label span {
			opacity: .85!important;
		}

		& + label {
			position: relative;
			width: 65px;
			height: 65px;
			border-radius: 6px;
			border: 1px solid #e7e8ec;
			background-color: #fff;
			display: block;
			cursor: pointer;
			box-sizing: border-box;

			&:before {
				content: '';
				width: 65px;
				height: 65px;
				border-radius: 6px;
				border: 2px solid #e7e8ec;
				display: inline-block;
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
				opacity: 0;
				box-sizing: border-box;
			}
		}

		&:checked + label {
			border-color: transparent;

			&:before {
				opacity: 1;
				border-color: #5c9ce6;
			}
		}
	}

	&__img {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		width: 31px;
		height: 31px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__none {
		background: url('../img/icons/noicon.svg') no-repeat;
		-webkit-background-size: contain;
		background-size: contain;
	}

	&__vk {
		background: url('../img/icons/vk blue.svg') no-repeat;
		-webkit-background-size: contain;
		background-size: contain;
	}

	&__vk-black {
		background: url('../img/icons/vk black.svg') no-repeat;
		-webkit-background-size: contain;
		background-size: contain;
	}

	&__add {
		color: #6190ca;
	}

	&_div-upload-icon {
		position: relative;
		width: 65px;
		height: 65px;
		border-radius: 6px;
		display: block;
		cursor: pointer;
		box-sizing: border-box;
		border: 2px dashed #6190ca!important;
		background: url("../img/icons/upload.svg") no-repeat center;
		background-size: 20px;
		transform: rotateZ(180deg);
	}
}

.Form-toggle {
	margin-top: 25px;

	input[type="checkbox"] {
		display: none;

		& + label:before {
			content: '';
			display: block;
			height: 9px;
			width: 28px;
			border-radius: 45px;
			position: absolute;
			top: 3px;
			right: -40px;
			background: #ced7e0;
		}

		& + label:after {
			content: ' ';
			display: block;
			height: 15px;
			width: 15px;
			border-radius: 50%;
			position: absolute;
			top: 0;
			right: -25px;
			background: #fff;
			box-shadow: inset 0 0 0 1px #b4bfcc;
		}

		&:checked + label::after {
			right: -42px;
			background: #5181b8;
			box-shadow: none;
		}

		&:checked + label::before {
			background: #bccde0;
		}
	}

	label {
		position: relative;
		font-size: 13px;
		font-weight: 500;
		letter-spacing: -0.1px;
		color: #000;
		cursor: pointer;
	}
}

.Form-add-img {
	margin-top: 25px;

	&__name {
		font-size: 13px;
		font-weight: 500;
		letter-spacing: -0.1px;
		color: #000000;
		margin-bottom: 10px;
	}

	&__box {
		display: flex;
		align-items: center;
	}

	&__img {
		width: 80px;
		height: 80px;
		border-radius: 4px;
		background: rgba(0, 28, 61, 0.08) url("../img/icons/camera.svg") no-repeat center;
		position: relative;

		&--rounded {
			border-radius: 50%;
		}

		&_close {
			position: absolute;
			width: 16px;
			height: 16px;
			background: rgba(0, 0, 0, 0.7) url("../img/icons/close-small.svg") no-repeat center center;
			background-size: 8px;
			border-radius: 2.5px;
			right: 5px;
			top: 5px;
			cursor: pointer;

			&:hover {
				background: rgba(0, 0, 0, 0.7) url("../img/icons/close-small-hover.svg") no-repeat center center;
				background-size: 8px;
			}
		}
	}

	&__info {
		margin-left: 15px;
	}

	&__text {
		font-size: 12.5px;
		letter-spacing: normal;
		color: #828282;
		margin-bottom: 10px;
	}
}

.Form-textarea {
	margin-top: 25px;

	&__name{
		font-size: 13px;
		font-weight: 500;
		letter-spacing: -0.1px;
		color: #000000;
	}

	textarea {
		width: 100%;
		resize: none;
		font-size: 13px;
		margin-top: 9px;
		border-radius: 4px;
		border: 1px solid #d3d9de;
		background-color: #fff;
		padding: 5px 10px;
		letter-spacing: -0.1px;
		box-sizing: border-box;
		font-family: inherit;
		outline: none;

		&::placeholder {
			font-size: 13px;
			letter-spacing: -0.1px;
			color: #828282;
		}
	}
}
