.About-url {
  position: relative;
  width: 15px;
  height: 15px;
  border-radius: 37.6px;
  margin-left: 7px;
  cursor: pointer;
  background: url("../img/icons/help.svg") no-repeat;
}

.About-url__text {
  opacity: 0;
  visibility: hidden;
  transition: all .5s ease-out;
  position: absolute;
  top: 30px;
  left: -39px;
  width: 310px;
  font-size: 13px;
  line-height: 1.31;
  letter-spacing: -0.1px;
  color: #000000;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid #c5d0db;
  background-color: #fff;
  padding: 18px 15px 13px 15px;
  cursor: auto;
  z-index: 1;
}

.About-url__text a {
  text-decoration: none;
}

.About-url__text:before {
  content: '';
  position: absolute;
  top: -6px;
  left: 40px;
  width: 10px;
  height: 10px;
  border-right: 1px solid #c5d0db;
  border-bottom: 1px solid #c5d0db;
  transform: rotate(-135deg);
  background: #fff;
}

.About-url__text p {
  margin: 0 0 10px;
}

.About-url__text p:last-child {
  margin: 0;
}

.About-url:hover .About-url__text {
  opacity: 1;
  visibility: visible;
}
