.geo {
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.04);
  margin-top: 25px;
  background: #eaedf0 url("../img/map.png") center no-repeat;
  background-size: contain;
  box-sizing: border-box;
  overflow: hidden;
  height: 240px;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
}
