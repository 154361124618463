.QR-block {
  padding-top: 26px;
  height: 100%;
  box-sizing: border-box;
}

.QR-block__qr {
  width: 250px;
  border-radius: 10px;
  background-color: #ffffff;
  margin-bottom: 26px;
}

.QR-block__qr svg {
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.08);
  border-radius: 10px;
}

.QR-block__buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.QR-block__buttons-b1, .QR-block__buttons-b2 {
  width: 121px;
}

.QR-block__buttons-b3 {
  width: 100%;
  margin-top: 10px;
}

.QR-error {
  font-size: 13px;
  line-height: 1.38;
  letter-spacing: -0.08px;
  color: #000000;
  border-radius: 6px;
  border: solid 1px #bcc8d6;
  background-color: #f5f7fa;
  margin-bottom: 26px;
  padding: 8px 12px;
  display: none;
}

.QR-error--show {
  display: block;
}
