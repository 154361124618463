.Retargeting {
  display: flex;
}

.Retargeting-left__icon {
  width: 65px;
  height: 65px;
  border: solid 0.5px rgba(0, 0, 0, 0.08);
  background-image: linear-gradient(135deg, #70b2ff, #5c9ce6);
  border-radius: 50%;
  position: relative;
}

.Retargeting-left__icon:after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  background-image: url("../img/icons/megafon.svg");
  background-repeat: no-repeat;
  background-size: 25px;
  background-position: 48% 52%;
}

.Retargeting-right {
  margin-left: 12px;
}

.Retargeting-right__title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Retargeting-right__title-caption {
  font-size: 16px;
  letter-spacing: -0.32px;
  color: #000000;
}

.Retargeting-right__title-close .Icon {
  cursor: pointer;
}

.Retargeting-right__desc {
  font-size: 13px;
  line-height: 1.31;
  letter-spacing: -0.26px;
  color: #828282;
  margin-top: 5px;
  margin-bottom: 11px;
}
