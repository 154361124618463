.Typography--title {
  font-size: 15px;
  font-weight: 500;
  letter-spacing: -0.24px;
  color: #000000;
}

.Typography--address, .Typography--site, .Typography--sale {
  margin-top: 15px;
  font-size: 13px;
  line-height: 1.31;
  letter-spacing: -0.14px;
  color: #2a5485;
  text-decoration: none;
  display: flex;
}

.Typography--sale {
  color: #43a843;
  font-weight: 500;
}

.Typography--site a {
  text-decoration: none;
}

.Typography--site a:hover {
  text-decoration: underline;
}

.Typography--icon {
  min-width: 17px;
  width: 17px;
  height: 19px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 13px;
}

.Typography--icon-sale {
  background-image: url("../img/icons/procent.svg");
}

.Typography--icon-address {
  background-image: url("../img/icons/place_outline_20.svg");
}

.Typography--icon-site {
  background-image: url("../img/icons/globe_outline_20.svg");
}

.Typography--address-show-all-addresses a {
  display: flex;
  text-decoration: none;
}

.Typography--address-show-all-addresses a:hover {
  text-decoration: underline;
}

.Typography + .Typography {
  margin-top: 30px;
}
