.Footer__review {
  font-size: 12px;
  line-height: 1.58;
  text-align: center;
  color: #939699;
  cursor: pointer;
  padding-top: 22px;
  padding-bottom: 22px;
}

.Footer__review:hover {
  opacity: 0.9;
}

.Footer__review-result {
  width: 300px;
  border-radius: 6px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  background-color: rgba(54, 56, 59, 0.96);
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  letter-spacing: -0.15px;
  color: #ffffff;
  padding: 12px 16px;
  position: fixed;
  left: 25px;
  bottom: 25px;
  display: none;
  z-index: 10;
}

.Footer__review-result--show {
  display: block;
}

.Footer__review-result_close {
  content: '';
  background: url("../img/icons/close-small.svg") no-repeat center;
  width: 10px;
  height: 10px;
  position: absolute;
  right: 10px;
  top: 10px;
  padding: 7px;
  background-size: 10px;
  cursor: pointer;
}

.Footer__review-result_close:hover {
  background-image: url("../img/icons/close-small-hover.svg");
}
