.ModalOwner {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
}

.Modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  filter: drop-shadow(0 4px 40px rgba(0, 0, 0, 0.2));
}

.Modal__content > div:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.Modal__download-icon {
  max-width: 450px;
  width: 100%;
}

.Modal__download-icon button {
  margin-top: 21px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.Modal__feedback {
  max-width: 510px;
  width: 100%;
}

.Modal__about {
  max-width: 910px;
  width: 100%;
}

.Modal__close {
  background: url("../img/icons/close.png") no-repeat;
  cursor: pointer;
  width: 15px;
  height: 22px;
  background-size: contain;
  position: absolute;
  right: 25px;
}

.Modal__header {
  background-color: #5b88bd;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  color: #fff;
  font-size: 14px;
  letter-spacing: -0.2px;
  padding: 19px 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Modal__body {
  padding: 26px 25px;
  background-color: #fff;
  max-height: calc(100vh - 160px);
  overflow-y: scroll;
}

.Modal__body::-webkit-scrollbar {
  display: none;
  width: 0;
  height: 0;
  background-color: transparent;
}

.Modal__body p {
  font-size: 13px;
  line-height: 1.38;
  letter-spacing: -0.1px;
  text-align: center;
  color: #000000;
  margin: 0;
}

.Modal .btn {
  font-size: 12.5px;
  color: #fff;
  text-align: center;
  padding: 7px 16px 8px 16px;
  border-radius: 4px;
  background-color: #5181b8;
  max-width: 120px;
  box-sizing: border-box;
  cursor: pointer;
}

.Modal__footer {
  border-top: 1px solid #e7e8ec;
  background-color: #f7f8fa;
  padding: 14px 25px;
  display: flex;
  justify-content: flex-end;
}

.Modal__footer > button + button {
  margin-left: 8px;
}

.Quote:first-child {
  margin-top: 12px;
}

.Quote:last-child {
  margin-bottom: 12px;
}

.Quote + .Quote {
  margin-top: 42px;
}

.Quote p:last-child {
  margin: 0;
}

.Quote__title {
  height: 18px;
  font-family: TTCommons;
  font-size: 25px;
  font-weight: 600;
  line-height: 0.72;
  letter-spacing: normal;
  color: #2c2d2e;
  margin-bottom: 22px;
}

.Quote__text {
  font-size: 15px;
  line-height: 1.47;
  letter-spacing: -0.2px;
  color: #000000;
  padding-left: 25px;
  border-left: 2px solid #b8c1cc;
  border-radius: 1px;
}
