.ColorPickerModal {
  position: absolute;
  z-index: 1;
  width: 100%;
  top: 0;
}

.ColorPicker {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 195px;
  border-radius: 8px;
  box-shadow: 0 4px 32px 0 rgba(0, 0, 0, 0.3);
  background: #fff;
  box-sizing: border-box;
  z-index: 1;
}

.ColorPicker * {
  box-sizing: border-box;
}

.ColorPicker__header {
  padding: 8px 10px;
  font-size: 13px;
  letter-spacing: -0.1px;
  color: #000;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.ColorPicker__content {
  padding: 10px;
}

.ColorPicker__content_panel {
  display: none;
}

.ColorPicker__content_panel--selected {
  display: block;
}

.ColorPicker__footer {
  padding: 0 10px 10px;
}

.ColorPicker__title {
  padding: 5px 10px;
  cursor: pointer;
  position: relative;
}

.ColorPicker__title--selected::after {
  content: '';
  position: absolute;
  bottom: -8px;
  width: 100%;
  left: 0;
  height: 2px;
  background-color: #5181b8;
}

.rc-color-picker-panel {
  all: unset;
}

.rc-color-picker-panel-inner {
  all: unset;
}

.rc-color-picker-panel-inner > div:nth-child(2) {
  height: 8px;
}

.rc-color-picker-panel-board {
  margin: 0;
}

.rc-color-picker-panel-board-hsv {
  width: 100%;
  height: 120px;
  border-radius: 4px;
}

.rc-color-picker-panel-board-handler {
  box-shadow: none;
}

.rc-color-picker-panel-wrap-ribbon {
  left: 0;
  right: 0;
  height: 100%;
}

.rc-color-picker-panel-wrap-ribbon > div {
  border-radius: 4px;
}

.rc-color-picker-panel-wrap-ribbon span {
  width: 8px;
  height: 12px;
  border-radius: 2px;
  box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.16);
  border: none;
  top: -2px;
}

.rc-color-picker-panel-wrap-preview {
  left: 0;
  bottom: -40px;
  z-index: 1;
  display: inline-table;
}

.rc-color-picker-panel-preview {
  width: 30px;
  height: 30px;
}

.rc-color-picker-panel-preview span {
  border-radius: 3px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rc-color-picker-panel-wrap:last-child {
  height: 30px !important;
  margin-top: 10px !important;
  border-radius: 4px;
  border: 1px solid #d3d9de;
}

.rc-color-picker-panel-params {
  font-family: SFProText;
  font-size: 13px;
  line-height: 1.46;
  letter-spacing: -0.1px;
  color: #000;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 35px;
  position: relative;
}

.rc-color-picker-panel-params:before {
  content: '#';
  position: absolute;
  left: 37px;
  font-size: 13px;
  top: 5px;
}

.rc-color-picker-panel-params-input input {
  display: none;
  border: none;
}

.rc-color-picker-panel-params-hex {
  display: block !important;
  width: 100%;
  height: 28px;
  text-align: left !important;
  font-size: 13px;
  letter-spacing: -0.1px;
  line-height: 1.46;
  padding: 2px !important;
  cursor: text;
}

.rc-color-picker-panel-params-hex:focus {
  outline: none;
}

.rc-color-picker-panel-params-lable {
  display: none;
}
