.Custom-select {
  position: relative;
}

.Custom-select__input-block {
  position: relative;
}

.Custom-select__input-block_icon {
  background: url("../img/icons/arrow.svg") no-repeat;
  position: absolute;
  width: 11px;
  height: 6px;
  right: 10px;
  top: 12px;
  pointer-events: none;
}

.Custom-select__input-block_text {
  cursor: pointer;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid #d3d9dd;
  height: 30px;
  padding: 6px 9px;
  font-size: 13px;
  line-height: 18px;
}

.Custom-select__input-block_text--open {
  border-radius: 4px 4px 0 0;
}

.Custom-select__input-block_text__placeholder {
  color: #828282;
}

.Custom-select__list {
  display: none;
  font-size: 13px;
  letter-spacing: -0.1px;
  color: #000000;
  border-radius: 0 0 4px 4px;
  box-shadow: 0 1px 1px 0 rgba(43, 47, 51, 0.17);
  border: solid 1px #d3d9de;
  background-color: #ffffff;
  border-top: none;
  position: absolute;
  z-index: 1;
  right: 0;
  left: 0;
  top: 30px;
  max-height: 145px;
  overflow-y: scroll;
}

.Custom-select__list--open {
  display: block;
}

.Custom-select__list_option {
  padding: 8px 9px 9px 9px;
  cursor: pointer;
}

.Custom-select__list_option:hover {
  background-color: #e7edf2;
}
