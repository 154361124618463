.toast {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #fff;
  border-top: 1px solid #E7E8EC;
  text-align: center;
  color: #828282;
  font-size: 14px;
  cursor: pointer;
  animation: showToast .15s;
  animation-fill-mode: forwards;

  &__inner {
    padding: 20px;
  }
}

@keyframes showToast {
  from {
    bottom: -56px;
  }
  to {
    bottom: 0;
  }
}