.Header {
  box-shadow: 0 1px 0 0 #e7e8ec;
  background-image: linear-gradient(100deg, #4787cf, #4880c0);
}

.Header__top-block {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding-top: 32px;
}

.Header__top-part {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.Header__account {
  display: flex;
}

.Header__account-ava {
  width: 30px;
  height: 30px;
  background-color: #ffffff;
  border-radius: 50%;
  margin-left: 8px;
}

.Header__description {
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: -0.2px;
  color: #ffffff;
  margin-top: 25px;
  margin-bottom: 22px;
}

.Header__about {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.36;
  letter-spacing: -0.2px;
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;
  width: 120px;
}

.Header__about:hover {
  opacity: .9;
}

.Header__about-active {
  font-weight: 600;
}

.Header__about .icon-triangle {
  width: 8px;
  height: 10px;
  display: inline-block;
  margin-left: 7.6px;
  background: url("../img/icons/chevron_16.svg") no-repeat;
}

.Top-logo {
  display: flex;
  align-items: flex-end;
}

.Top-logo__icon {
  width: 31.7px;
  height: 31.7px;
  background: url("../img/icons/vk_white.svg") no-repeat;
  background-size: contain;
}

.Top-logo__title {
  font-size: 24px;
  font-family: TTCommons;
  font-weight: 500;
  color: #ffffff;
  margin-left: 13px;
  line-height: 25px;
}

.Top-menu {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
  margin-top: 31px;
  display: flex;
}

.Top-menu__button {
  margin-right: 30px;
  height: 54px;
  display: flex;
  align-items: center;
  font-family: SFProText;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: -0.1px;
  color: rgba(255, 255, 255, 0.75);
  text-decoration: none;
  position: relative;
  padding-left: 5px;
  padding-right: 5px;
  cursor: pointer;
}

.Top-menu__button:hover {
  opacity: .9;
}

.Top-menu__button-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  background: no-repeat center;
  opacity: .75;
}

.Top-menu__button-icon--link {
  background-image: url("../img/icons/link.svg");
}

.Top-menu__button-icon--wifi {
  background-image: url("../img/icons/wifi.svg");
}

.Top-menu__button-icon--contact {
  background-image: url("../img/icons/card.svg");
}

.Top-menu__button-icon--text {
  background-image: url("../img/icons/text.svg");
}

.Top-menu__button-icon--event {
  background-image: url("../img/icons/event.svg");
}

.Top-menu__button-icon--geo {
  background-image: url("../img/icons/geo.svg");
}

.Top-menu__button--active {
  color: #fff;
}

.Top-menu__button--active:before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 2px;
  background-color: #ffffff;
}

.Top-menu__button--active .Top-menu__button-icon {
  opacity: 1;
}

.HeaderSmall {
  box-shadow: 0 1px 0 0 #e7e8ec;
  background-image: linear-gradient(100deg, #4787cf, #4880c0);
}

.HeaderSmall__top-block {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 60px;
}

.HeaderSmall__left {
  display: flex;
}

.HeaderSmall__right {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.HeaderSmall__right_account-ava {
  width: 30px;
  height: 30px;
  background-color: #ffffff;
  border-radius: 50%;
  margin-left: 8px;
}

.HeaderSmall__right_account-name {
  font-size: 13px;
  font-weight: 500;
  letter-spacing: -0.08px;
  color: #ffffff;
  margin-left: 30px;
}

.HeaderSmall__right_title {
  font-size: 13px;
  font-weight: 500;
  letter-spacing: -0.08px;
  color: #ffffff;
}

.SmallTop-logo {
  display: flex;
  align-items: flex-end;
  cursor: pointer;
}

.SmallTop-logo__icon {
  width: 23.8px;
  height: 23.8px;
  background: url("../img/icons/vk_white.svg") no-repeat;
  background-size: contain;
}

.SmallTop-logo__title {
  font-size: 18.4px;
  font-family: TTCommons;
  font-weight: 500;
  color: #ffffff;
  margin-left: 11.1px;
  line-height: 18px;
}

.back-fixed {
  position: fixed;
  top: 60px;
  opacity: 0.5;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.15px;
  color: #2a5885;
  cursor: pointer;
  height: calc(100vh - 60px);
  width: calc(50vw - 475px);
}

.back-fixed:hover {
  opacity: 1;
}

.back-fixed:hover .back-label {
  background-color: #dde2e7;
}

.back-fixed .back-label {
  display: flex;
  flex-direction: row;
  width: 100px;
  padding-left: 25px;
  padding-top: 25px;
  height: 100%;
}

.back-fixed .back-label .icon {
  width: 10.5px;
  height: 16.5px;
  background: url("../img/icons/chevron_16_blue.svg") no-repeat;
  background-size: contain;
  transform: rotateZ(180deg);
  margin-right: 12.5px;
}

@media (max-width: 1200px) {
  .back-fixed {
    display: none;
  }
}
